
.sheet {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  display: inline-block;
}

.instruction {
  padding-bottom: 30px;
}

p {
  color:#949494;
  font-family: 'Montserrat';
  margin: 0 0 5px 0;
}

p{
  a{
    color: #008CBA;
    text-decoration: none;
  }

}

$color-light: #fff;
$color-dark: #222f3e;
$color-background: #c8d6e5;
$color-primary: #ff9f43;
$color-secondary: rgb(167, 158, 158);
$color-success:rgb(185, 181, 181);
$radius: 5px;
$base-size: 12px;
$transition: all 0.5s; 

.button {
  float: right;
  background: white;
  border: 2px solid #008CBA;
  text-align: center;
  line-height: 1.3;
  padding: 10px 25px;
  margin: 25px 5px;
  border-radius: $radius;
  font-weight: 700;
  text-transform: uppercase;
  transition: $transition;

  &:hover {
    background: #EAF0F6;
  }
}

.switchButton {
  margin-top: 20px;
  display: flex;
  float: right;
  padding-bottom: 25px;
}

.switchButton p {
  margin-right: 20px;
}



h1 {
  display: inline-block;
  text-align: left;
  margin: 30px 0 10px;
  color:#003344;
  font-family: 'Open Sans';
}