.formDiv{
  border-top: 2px solid #008CBA;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 40px;;
}

.formDiv >:first-child {
  text-align: center;
}

.form {
  color:#949494;
  font-family: 'Montserrat';
  width: 40%;
  margin: auto;
}

.form label {
  display: block;
  margin: 30px 0 30px 0;
}



.form label p {
  float: left;
  display: block;
  width: 100%;
}

.frame {
  border: 1px solid #008CBA;
  border-radius: 4px;
  width: 100%;
  padding: 3px;
}

.submitButton {
  background: white;
  border: 2px solid #008CBA;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.5s; 
  float: right;

  &:hover {
    background: #EAF0F6;
  }
}

@media screen and (max-width: 992px) {
  .form {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .form {
    width: 90%;
  }
}