body {
  margin: 0;
}


.app {
  width: 80%;
  margin: auto;
}

table.data-grid {
  margin: auto;
  width: 100%;
}
  span.data-grid-container, span.data-grid-container:focus {
    outline: none;
  }

  .data-grid-container .data-grid {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-color: #008CBA;
  } 

  .data-grid-container .data-grid .cell.updated {
      background-color: rgba(0, 145, 253, 0.16);
      transition : background-color 0ms ease ;
  }
  .data-grid-container .data-grid .cell {
    height: 30px;
    color:#003344;
    font-size: 14px;
    font-family: 'Montserrat';
    padding: 5px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    background-color: unset;
    transition : background-color 500ms ease;
    vertical-align: middle;
    text-align: right;
    border: 1px solid #DDD;
  }
  .data-grid-container .data-grid .cell.selected {
    border: 1px double rgb(33, 133, 208);
    transition: none;
    box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
  }

  .data-grid-container .data-grid .cell.unsupportedValue {
    background-color: #FFBAB8;
    border: 1px double #B80500;
  }

  .data-grid-container .data-grid .cell.read-only {
    background: #fafafa;
    color:#949494;
    text-align: center;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: bold;
  }

  .data-grid-container .data-grid .cell > .text {
    padding: 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: 2px solid rgb(33, 133, 208);
    text-align:right;
    width: calc(100% - 6px);
    height: 11px;
    background: none;
    display: block;
  }


  .data-grid-container .data-grid .cell {
    vertical-align: bottom;
  }

  .data-grid-container .data-grid .cell,
  .data-grid-container .data-grid.wrap .cell,
  .data-grid-container .data-grid.wrap .cell.wrap,
  .data-grid-container .data-grid .cell.wrap,
  .data-grid-container .data-grid.nowrap .cell.wrap,
  .data-grid-container .data-grid.clip .cell.wrap {
    white-space: normal;
  }

  .data-grid-container .data-grid.nowrap .cell,
  .data-grid-container .data-grid.nowrap .cell.nowrap,
  .data-grid-container .data-grid .cell.nowrap,
  .data-grid-container .data-grid.wrap .cell.nowrap,
  .data-grid-container .data-grid.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
  }

  .data-grid-container .data-grid.clip .cell,
  .data-grid-container .data-grid.clip .cell.clip,
  .data-grid-container .data-grid .cell.clip,
  .data-grid-container .data-grid.wrap .cell.clip,
  .data-grid-container .data-grid.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
  }

  .data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
    display: block;
  }

  .box {
    display: none;
  }

  

  @media screen and (max-width: 992px) {
    .data-grid-container .data-grid {
      table-layout: auto;
      overflow-x: scroll;
    } 

    .box {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      background: white;
      border: 2px solid #008CBA;
      line-height: 1.3;
      padding: 10px 25px;
      margin: 30px auto 0 auto;
      font-weight: 700;
      border-radius: 5px;;
    }

    .box p {
      display: inline;
      margin-right: 15px;
    }

    .box button {
      background-color: white;
      border: 1px solid;
      border-radius: 2px;;
    }

    .hidden {
      display: none;
    }
  }